"use client"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import NextLink from "next/link"
import '../assets/css/error.css'

const NotFound = ({ error }) => {
  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      zIndex: 99999,
      backgroundColor: '#eaeaea',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <div className='error'>
        <h1>{error?.code || 500}</h1>
        <Stack justifyContent="center" alignItems="center" flexDirection="column">
          <h2>Service Unavailable <b>:(</b></h2>
          <Box
            className='btn-back'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: "30px",
              '& a': {
                textDecoration: 'underline',
                fontWeight: 300,
                fontSize: '1.8rem',
                borderRadius: '5px',
                transition: 'all 0.3s ease',
                color: '#000',
                '&:hover': {
                  opacity: 0.8
                }
              }
            }}>
            <NextLink href='/' passHref>
              Back to Home
            </NextLink>
          </Box>
        </Stack>
        <div className="gears">
          <div className="gear one">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className="gear two">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className="gear three">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      
      </div>
    </Box>
  )
}

export default NotFound
